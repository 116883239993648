@tailwind base;
@tailwind components;
@tailwind utilities;

.amplify-login button,
.amplify-login [type='button']
    {
    background-color: #315de3 !important; 
    color: white !important;
    -webkit-appearance: none; 
}